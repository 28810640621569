<!-- 裁剪图片 -->
<template>
  <div class="wrapper">
      <div class="background" style="background-color: slategrey;">
      	<!-- <img :src="imgSrc" width="100%" height="100%" alt="" /> -->
      </div>
      <!-- <div style=""></div> -->
      <div>
        
        <van-button size="large" round plain hairline type="primary" @click="popupAgv(item)"
        v-for="(item,index) in agvs" :title="item.agvId" :name="index" :key="index">
          {{item.agvId}}号叉车
        </van-button>
      </div>
      <div class="AgvDetail">
        <van-popup v-model="show" @close="flushPage">
          <van-card
            :tag="agvSingle.taskId"
            :desc="agvSingle.agvIp"  
            :title="agvSingle.agvId"
            :thumb="imgAgv"
          >
            <div slot="footer">
              <van-circle
                v-model="currentRate"
                :rate="currentRate"
                :speed="100"
                :text="text"
                size="50px"
              />
            </div>
          </van-card>
        </van-popup>
      </div>
  </div>
</template>

<script>

  import {Dialog} from "vant"
  import {ref} from "vue"
  // import * as OSS from 'ali-oss';
  export default {
    components: {
      [Dialog.Component.name]: Dialog.Component,
    },
    data() {
      return {
        model: false,
        modelSrc: '',
        crap: false,
        previews: {},
        show: false,
        imgAgv: require("/src/assets/agvIco.png"),
        // apiFlag :"Checking",
        form: {
          head: ''
        },
        oriImg: '',
        oriImgType: '',
        apiUrl: "http://192.168.200.23:7844/agvserver/",
        agvs :[],
        chargePos_one: 0,
        chargePos_two: 0,
        agvSingle: '',
        currentRate: 0
      }
    },
    setup(){
        const fileList = ref([
              {
                base64: this.modelSrc,
                deletable: false,
                imageFit: 'contain',
                previewSize: 500,
              },
            ]);
        return { fileList };
    },
    computed: {
      text() {
          return this.currentRate.toFixed(0) + '%'
        }
      },
    methods: {
        // onRead(file) {
        //     this.ifimg = true;
        //     this.modelSrc = file.content
        //     this.oriImg = file
        //     this.oriImgType = file.file.type
        //     let canvas = document.createElement('canvas'); // 创建Canvas对象(画布)
        //     let context = canvas.getContext('2d');
        //     let img = new Image();
        //     img.src = file.content;                         // 指定图片的DataURL(图片的base64编码数据)
        //     img.onload = function () {
        //         // 画布大小 这里的this指向img
        //         canvas.width = this.width;
        //         canvas.height = this.height;
        //         context.drawImage(img, 0, 0, this.width, this.height);  // 图片大小
        //         file.content = canvas.toDataURL(file.file.type, 0.3);   // 0.92为默认压缩质量
        //         this.oriImg = file.content
        //     };
        // },
        popupAgv(item) {
          if(!item.taskId){
            item.taskId = "无任务"
          }
          var agvId = item.agvId
          item.agvId = String(agvId)
          this.currentRate = item.agvBattery
          console.log(this.currentRate)
          this.agvSingle = item
          console.log(this.agvSingle)

          this.show = true
        },
        flushPage(){
          location.reload()
        },
        getAgvCar(){
           this.$http.get(this.apiUrl+"agvstatus")
           .then(result =>{
               console.log(result)
               var result_body = result["body"]
               this.agvs = []
               for(var singlefloor in result_body){
                   console.log(singlefloor)
                   if (!singlefloor.search("charge")){
                       console.log(66)
                       continue
                   }
                   for(var singleagv in result_body[singlefloor]){
                       if (!result_body[singlefloor][singleagv]){
                           continue
                       }
                       // console.log(55)
                       // console.log(singleOne)
                       // const singleOne = new Map()
                       // singleOne.set(singleagv, result_body[singlefloor][singleagv])
                       this.agvs.push(result_body[singlefloor][singleagv])
                   }
                   
               }
               console.log(this.agvs)
           }).catch(error=>{console.log(error)}) 
        }
    },
    created: function(){
        this.getAgvCar()
    }
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }

  .content {
    margin: auto;
    max-width: 585px;
    margin-bottom: 100px;
  }

  .test-button {
    display: flex;
    flex-wrap: wrap;
  }

  .btn {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #c0ccda;
    color: #1f2d3d;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 20px 10px 0px 0px;
    padding: 9px 15px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    background-color: #50bfff;
    border-color: #50bfff;
    transition: all .2s ease;
    text-decoration: none;
    user-select: none;
  }

  .des {
    line-height: 30px;
  }

  code.language-html {
    padding: 10px 20px;
    margin: 10px 0px;
    display: block;
    background-color: #333;
    color: #fff;
    overflow-x: auto;
    font-family: Consolas, Monaco, Droid, Sans, Mono, Source, Code, Pro, Menlo, Lucida, Sans, Type, Writer, Ubuntu, Mono;
    border-radius: 5px;
    white-space: pre;
  }

  .show-info {
    margin-bottom: 50px;
  }

  .show-info h2 {
    line-height: 50px;
  }

  /*.title, .title:hover, .title-focus, .title:visited {
        color: black;
    }*/

  .title {
    display: block;
    text-decoration: none;
    text-align: center;
    line-height: 1.5;
    margin: 20px 0px;
    background-image: -webkit-linear-gradient(left, #3498db, #f47920 10%, #d71345 20%, #f7acbc 30%, #ffd400 40%, #3498db 50%, #f47920 60%, #d71345 70%, #f7acbc 80%, #ffd400 90%, #3498db);
    color: transparent;
    -webkit-background-clip: text;
    background-size: 200% 100%;
    animation: slide 5s infinite linear;
    font-size: 40px;
  }

  .test {
    height: 285px;
  }

  .model {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }

  .model-show {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  .model img {
    display: block;
    margin: auto;
    max-width: 80%;
    user-select: none;
    background-position: 0px 0px, 10px 10px;
    background-size: 20px 20px;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
  }

  .c-item {
    display: block;
    padding: 10px 0;
    user-select: none;
  }

  @keyframes slide {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: -100% 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .content {
      max-width: 90%;
      margin: auto;
    }

    .test {
      height: 400px;
    }
  }
</style>