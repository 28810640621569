<!-- 裁剪图片 -->
<template>
	<div class="wrapper">
		<div class="background" style="background-color: slategrey;">
			<!-- <img :src="imgSrc" width="100%" height="100%" alt="" /> -->
		</div>
		<!-- <div style=""></div> -->
		<div>
			<van-cell-group>
				<van-cell center title="2.5公斤自动">
					<template #right-icon>
						<van-switch v-model="checked_2_5" @change="change2_5" />
					</template>
				</van-cell>
				<van-cell center title="25公斤自动">
					<template #right-icon>
						<van-switch v-model="checked_25" @change="change25"/>
					</template>
				</van-cell>
				<van-cell center title="5公斤自动">
					<template #right-icon>
						<van-switch v-model="checked_5" @change="change5" />
					</template>
				</van-cell>
			</van-cell-group>
		</div>
		<div>
			<van-tabs type="card">
				<van-tab title="2.5公斤预设库位">
					<van-field
						v-model="col_2_5Al"
						required
						clearable
						label="已有库位数"
					/>
					<van-field
						v-model="cols_2_5"
						required
						clearable
						label="已有库位"
						type="textarea"
						autosize
					/>
					<van-button type="danger" @click="delete2_5">清除库位</van-button>
					<van-radio-group v-model="radio" style="padding: 2rem;">
						<van-row>
							<van-col>
								<van-radio name="1">北区</van-radio>
							</van-col>
							<van-col>
								<van-radio name="2">南区</van-radio>
							</van-col>
						</van-row>
					</van-radio-group>
					<van-field
						v-model="col_2_5"
						required
						clearable
						label="2.5公斤列"
						type="number"
						placeholder="请输入预设列"
					/>
					<van-field
						v-model="row_2_5_S"
						required
						clearable
						type="number"
						label="2.5公斤起点"
						placeholder="请输入预设行"
					/>
					<van-field
						v-model="row_2_5_E"
						required
						type="number"
						clearable
						label="2.5公斤终点"
						placeholder="请输入预设行"
					/>
					<van-button type="info" @click="submit2_5">添加库位</van-button>
				</van-tab>
				<van-tab title="25公斤预设库位" >
					<van-field
						v-model="col_25_Al"
						required
						clearable
						label="已有库位数"
					/>
					<van-field
						v-model="cols_25"
						required
						clearable
						label="已有库位"
						type="textarea"
						autosize
					/>
					<van-button type="danger" @click="delete25">清除库位</van-button>
					<van-radio-group v-model="radio" style="padding: 2rem;">
						<van-row>
							<van-col>
								<van-radio name="1">北区</van-radio>
							</van-col>
							<van-col>
								<van-radio name="2">南区</van-radio>
							</van-col>
						</van-row>
					</van-radio-group>
					<van-field
						v-model="col_25"
						required
						clearable
						label="25公斤列"
						type="number"
						placeholder="请输入预设列"
					/>
					<van-field
						v-model="row_25_S"
						required
						clearable
						type="number"
						label="25公斤起点行"
						placeholder="请输入预设行"
					/>
					<van-field
						v-model="row_25_E"
						required
						clearable
						type="number"
						label="25公斤终点"
						placeholder="请输入预设行"
					/>
					<van-button type="info" @click="submit25">添加库位</van-button>
				</van-tab>
				<van-tab title="5公斤预设库位" >
					<van-field
						v-model="col_5_Al"
						required
						clearable
						label="已有库位数"
					/>
					<van-field
						v-model="cols_5"
						required
						clearable
						label="已有库位"
						type="textarea"
						autosize
					/>
					<van-button type="danger" @click="delete5">清除库位</van-button>
					<van-radio-group v-model="radio" style="padding: 2rem">
						<van-row>
							<van-col>
								<van-radio name="1">北区</van-radio>
							</van-col>
							<van-col>
								<van-radio name="2">南区</van-radio>
							</van-col>
						</van-row>
					</van-radio-group>
					<van-field
						v-model="col_5"
						required
						clearable
						label="5公斤列"
						type="number"
						placeholder="请输入预设列"
					/>
					<van-field
						v-model="row_5_S"
						required
						clearable
						type="number"
						label="5公斤起点行"
					/>
					<van-field
						v-model="row_5_E"
						required
						clearable
						type="number"
						label="5公斤终点"
						placeholder="请输入预设行"
					/>
					<van-button type="info" @click="submit5">添加库位</van-button>
				</van-tab>
			</van-tabs>
		</div>
		<div class= "BottomGuide">
			<router-view />
			<van-tabbar route active-color="#00cc00">
			<van-tabbar-item replace to="/" icon="home-o">{{$t("bottom.home")}}</van-tabbar-item>
			<van-tabbar-item replace to="/main" icon="star-o">{{$t("bottom.main")}}</van-tabbar-item>
			<van-tabbar-item replace to="/advan" icon="fire-o" name="advan-q">{{$t("bottom.advan")}}</van-tabbar-item>
			<van-tabbar-item replace to="/pack" icon="bag-o" name="package-q">{{$t("bottom.pack")}}</van-tabbar-item>
			</van-tabbar>
		</div>
	</div>
  </template>
  
  <script>
  
	import {Dialog} from "vant"
	import {ref} from "vue"
	// import * as OSS from 'ali-oss';
	export default {
	  components: {
		[Dialog.Component.name]: Dialog.Component,
	  },
	  data() {
		return {
	      active: "home-q",
		  radio: '1',
		  radioOut: '1',
		  checked_25: false,
		  checked_2_5: false,
		  checked_5: false,

		  cols_5:0,
		  cols_2_5:0,
		  cols_25:0,
		  
		  col_2_5: 0,
		  col_25: 0,
		  col_5: 0,

		  row_2_5_S: 0,
		  row_2_5_E: 15,

		  row_5_S: 0,
		  row_5_E: 15,

		  row_25_S: 0,
		  row_25_E: 15,

		  col_2_5Al: 0,
		  col_25_Al: 0,
		  col_5_Al: 0,

		  leftCol5: "",
		  listCol5: [],
		  listCol2_5: [], 
		  listCol25: [],
		  leftCol2_5: "",
		  leftCol25: "",
		  imgAgv: require("/src/assets/agvIco.png"),
		  // apiFlag :"Checking",
		  form: {
			head: ''
		  },
		  oriImg: '',
		  oriImgType: '',
		  apiUrl: "http://192.168.200.23:7844/agvserver/",
		  agvs :[],
		  chargePos_one: 0,
		  chargePos_two: 0,
		  agvSingle: '',
		  currentRate: 0,

		  outCol: 0,
		  outStartRow: 0,
		  outEndRow: 15,
		}
	  },
	  setup(){
		  const fileList = ref([
				{
				  base64: this.modelSrc,
				  deletable: false,
				  imageFit: 'contain',
				  previewSize: 500,
				},
			  ]);
		  return { fileList };
	  },
	  computed: {
		text() {
			return this.currentRate.toFixed(0) + '%'
		  }
		},
	  methods: {
		  popupAgv(item) {
			if(!item.taskId){
			  item.taskId = "无任务"
			}
			var agvId = item.agvId
			item.agvId = String(agvId)
			this.currentRate = item.agvBattery
			console.log(this.currentRate)
			this.agvSingle = item
			console.log(this.agvSingle)
  
			this.show = true
		  },
		  flushPage(){
			location.reload()
		  },
		  getAuto(){
			 this.$http.get(this.apiUrl+"infochange")
			 .then(result =>{
				 console.log(result)
				 result = result.body
				 if (result.autoIn25 > 0){
					console.log()
					this.checked_25 = true
				 }
				 if (result.autoIn5 > 0){
					this.checked_5 = true
				 }
				 if (result["autoIn2.5"] > 0){
					this.checked_2_5 = true
				 }
			 }).catch(error=>{console.log(error)}) 
		  },
		  getStorage(){
			 this.$http.get(this.apiUrl+"storageset")
			 .then(result =>{
				 console.log(result)
				 result = result.body
				 if (result["2.5"]["storageReadyNum"] > 0){
					this.col_2_5Al = result["2.5"]["storageReadyNum"]
					var listStorage2_5 = []
					var listCol2_5 = []
					for(var singleStorage2_5 in result["2.5"]["storageReadyLeftStorage"]){
						// console.log(result["2.5"]["storageReadyLeftStorage"][singleStorage2_5])
						var thisStorage2_5 = result["2.5"]["storageReadyLeftStorage"][singleStorage2_5].split(",")
						// console.log(thisStorage2_5)
						if(listCol2_5.indexOf(thisStorage2_5[2])<=-1 ){
							// console.log(1)
							listCol2_5.push(thisStorage2_5[2])
						}
						thisStorage2_5 = String(thisStorage2_5[0])+String(thisStorage2_5[1])+"区"+String(thisStorage2_5[2]) + "列" + String(thisStorage2_5[3]) + "行"
						// console.log(thisStorage2_5)
						listStorage2_5.push(thisStorage2_5)
					}
					console.log(listCol2_5)
					this.listCol2_5 = listCol2_5
					this.cols_2_5 = String(listStorage2_5)
				 }
				 if (result["25"]["storageReadyNum"] > 0){
					this.col_25_Al = result["25"]["storageReadyNum"]
					var listStorage25 = []
					var listCol25 = []
					for(var singleStorage25 in result["25"]["storageReadyLeftStorage"]){
						// console.log(result["25"]["storageReadyLeftStorage"][singleStorage25])
						var thisStorage25 = result["25"]["storageReadyLeftStorage"][singleStorage25].split(",")
						// console.log(thisStorage25)
						if(listCol25.indexOf(thisStorage25[2])<=-1 ){
							// console.log(1)
							listCol25.push(thisStorage25[2])
						}
						thisStorage25 = String(thisStorage25[0])+String(thisStorage25[1])+"区"+String(thisStorage25[2]) + "列" + String(thisStorage25[3]) + "行"
						// console.log(thisStorage25)
						listStorage25.push(thisStorage25)
					}
					console.log(listCol25)
					this.listCol25 = listCol25
					this.cols_25 = String(listStorage25)
				 }
				 if (result["5"]["storageReadyNum"] > 0){
					this.col_5_Al = result["5"]["storageReadyNum"]
					var listStorage5 = []
					var listCol5 = []
					for(var singleStorage5 in result["5"]["storageReadyLeftStorage"]){
		
						// console.log(result["5"]["storageReadyLeftStorage"][singleStorage5])
						var thisStorage5 = result["5"]["storageReadyLeftStorage"][singleStorage5].split(",")
						// console.log(thisStorage5)
						if(listCol5.indexOf(thisStorage5[2])<=-1 ){
							// console.log(1)
							listCol5.push(thisStorage5[2])
						}
						thisStorage5 = String(thisStorage5[0])+String(thisStorage5[1])+"区"+String(thisStorage5[2]) + "列" + String(thisStorage5[3]) + "行"
						// console.log(thisStorage5)
						listStorage5.push(thisStorage5)
					}
					console.log(listCol5)
					this.listCol5 = listCol5
					this.cols_5 = String(listStorage5)
				 }
			 }).catch(error=>{console.log(error)}) 
		  },
		  submit2_5(){
			if (Number(this.radio) === 1){
				if (Number(this.col_2_5) < 0 || Number(this.col_2_5) > 7){
					alert("北区库位列只能在0到7之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_2_5_S) < 0 || Number(this.row_2_5_S) > 15){
					alert("北区库位行只能在0到15之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_2_5_E) < 0 || Number(this.row_2_5_E) > 15){
					alert("北区库位行只能在0到15之间")
					location.reload()
					return void 0
				}
			}
			if (Number(this.radio) === 2){
				if (Number(this.col_2_5) < 0 || Number(this.col_2_5) > 9){
					alert("南区库位列只能在0到9之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_2_5_S) < 0 || Number(this.row_2_5_S) > 6){
					alert("南区库位行只能在0到6之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_2_5_E) < 0 || Number(this.row_2_5_E) > 6){
					alert("南区库位行只能在0到6之间")
					location.reload()
					return void 0
				}
			}
			var postbody = {}
			// var startNum = Number(this.row_5_S)
			var startNum = Number(this.row_2_5_S)
			console.log(typeof(this.row_2_5_S))
			console.log(typeof(this.row_2_5_E))
			var endNum = Number(this.row_2_5_E)
			postbody["flag"] = "set"
			const numbers = [];
			for (let i = startNum; i <= endNum; i++) {
				numbers.push(i);
			}
			let colbody = {}
			colbody[this.col_2_5] = numbers
			// let areabody = {'A+${this.radio}' :colbody}
			let areabody = {}
			areabody["A0"+this.radio] = colbody
			postbody["2.5"] = areabody
			console.log(postbody)
			this.$http.post(this.apiUrl+"storageset/", postbody, "application/json")
			 .then(result =>{
				//  console.log(result)
				if(result.status == 200){
					alert("设定成功")
				}
				location.reload()
			 }).catch(error=>{
				alert(error.bodyText)
				// console.log(error)
				location.reload()
			}) 
		  },
		  delete2_5(){
			var postbody = {}
			// postbody.set("flag", "delete")
			var colbody = {}
			if (this.listCol2_5.length < 1){
				alert("无库位可删除")
				// this.getStorage()
				location.reload()
				return 0
			}
			for(var singleCol in this.listCol2_5) {
				console.log(this.listCol2_5[singleCol])
				colbody[String(this.listCol2_5[singleCol])]= [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
			}
			var areabody = {};
			areabody['A01'] = colbody;
			postbody['flag'] = 'delete';
			postbody["2.5"] = areabody
			console.log(postbody)
			this.$http.post(this.apiUrl+"storageset/", postbody, "application/json")
			 .then(result =>{
				if(result.status == 200){
					alert("删除成功")
					location.reload()
				}
			 }).catch(result =>{
				alert(result.bodyText)
				location.reload()
			 })
			//  location.reload()
		  },
		  submit25(){
			if (Number(this.radio) === 1){
				if (Number(this.col_25) < 0 || Number(this.col_25) > 7){
					alert("北区库位列只能在0到7之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_25_S) < 0 || Number(this.row_25_S) > 15){
					alert("北区库位行只能在0到15之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_25_E) < 0 || Number(this.row_25_E) > 15){
					alert("北区库位行只能在0到15之间")
					location.reload()
					return void 0
				}
			}
			if (Number(this.radio) === 2){
				if (Number(this.col_25) < 0 || Number(this.col_25) > 9){
					alert("南区库位列只能在0到9之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_25_S) < 0 || Number(this.row_25_S) > 6){
					alert("南区库位行只能在0到6之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_25_E) < 0 || Number(this.row_25_E) > 6){
					alert("南区库位行只能在0到6之间")
					location.reload()
					return void 0
				}
			}
			var postbody = {}
			// var startNum = Number(this.row_5_S)
			var startNum = Number(this.row_25_S)
			console.log(typeof(this.row_25_S))
			console.log(typeof(this.row_25_E))
			var endNum = Number(this.row_25_E)
			postbody["flag"] = "set"
			const numbers = [];
			for (let i = startNum; i <= endNum; i++) {
				numbers.push(i);
			}
			
			let colbody = {}
			colbody[this.col_25] = numbers
			let areabody = {}
			areabody["A0"+this.radio] = colbody
			postbody["25"] = areabody
			console.log(postbody)
			this.$http.post(this.apiUrl+"storageset/", postbody, "application/json")
			 .then(result =>{
				//  console.log(result)
				if(result.status == 200){
					alert("设定成功")
				}
				location.reload()
			 }).catch(error=>{
				alert(error.bodyText)
				location.reload()
			}) 
		  },
		  delete25(){
			var postbody = {}
			// postbody.set("flag", "delete")
			var colbody = {}
			if (this.listCol25.length < 1){
				alert("无库位可删除")
				// this.getStorage()
				location.reload()
				return 0
			}
			for(var singleCol in this.listCol25) {
				console.log(this.listCol25[singleCol])
				colbody[String(this.listCol25[singleCol])]= [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
			}
			var areabody = {};
			areabody['A01'] = colbody;
			postbody['flag'] = 'delete';
			postbody["25"] = areabody
			console.log(postbody)
			this.$http.post(this.apiUrl+"storageset/", postbody, "application/json")
			 .then(result =>{
				if(result.status == 200){
					alert("删除成功")
					location.reload()
				}
			 }).catch(result =>{
				alert(result.bodyText)
				location.reload()
			 })
			//  location.reload()
		  },
		  submit5(){
			if (Number(this.radio) === 1){
				if (Number(this.col_5) < 0 || Number(this.col_5) > 7){
					alert("北区库位列只能在0到7之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_5_S) < 0 || Number(this.row_5_S) > 15){
					alert("北区库位行只能在0到15之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_5_E) < 0 || Number(this.row_5_E) > 15){
					alert("北区库位行只能在0到15之间")
					location.reload()
					return void 0
				}
			}
			if (Number(this.radio) === 2){
				if (Number(this.col_5) < 0 || Number(this.col_5) > 9){
					alert("南区库位列只能在0到9之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_5_S) < 0 || Number(this.row_5_S) > 6){
					alert("南区库位行只能在0到6之间")
					location.reload()
					return void 0
				}
				if (Number(this.row_5_E) < 0 || Number(this.row_5_E) > 6){
					alert("南区库位行只能在0到6之间")
					location.reload()
					return void 0
				}
			}
			var postbody = {}
			// var startNum = Number(this.row_5_S)
			var startNum = Number(this.row_5_S)
			console.log(typeof(this.row_5_S))
			console.log(typeof(this.row_5_E))
			var endNum = Number(this.row_5_E)
			postbody["flag"] = "set"
			const numbers = [];
			for (let i = startNum; i <= endNum; i++) {
				numbers.push(i);
			}
			let colbody = {}
			colbody[this.col_5] = numbers
			let areabody = {}
			areabody["A0"+this.radio] = colbody
			postbody["5"] = areabody
			console.log(postbody)
			this.$http.post(this.apiUrl+"storageset/", postbody, "application/json")
			 .then(result =>{
				//  console.log(result)
				if(result.status == 200){
					alert("设定成功")
				}
				location.reload()
			 }).catch(error=>{
				alert(error.bodyText)
				location.reload()
			}) 
			
			// postBody = 	
		// 	this.$http.post(this.apiUrl+"storageset", body)
		// 	 .then(result =>{
		// 		 console.log(result)
		// 		 result = result.body
		// 		 if (result.autoIn25 > 0){
		// 			console.log(1)
		// 			this.checked_25 = true
		// 		 }
		// 		 if (result.autoIn5 > 0){
		// 			this.checked_5 = true
		// 		 }
		// 		 if (result["autoIn2.5"] > 0){
		// 			this.checked_2_5 = true
		// 		 }
		// 	 }).catch(error=>{console.log(error)}) 
		  },
		  delete5(){
			var postbody = {}
			// postbody.set("flag", "delete")
			var colbody = {}
			if (this.listCol5.length < 1){
				alert("无库位可删除")
				// this.getStorage()
				location.reload()
				return 0
			}
			for(var singleCol in this.listCol5) {
				console.log(this.listCol5[singleCol])
				colbody[String(this.listCol5[singleCol])]= [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
			}
			var areabody = {};
			areabody['A01'] = colbody;
			postbody['flag'] = 'delete';
			postbody["5"] = areabody
			console.log(postbody)
			this.$http.post(this.apiUrl+"storageset/", postbody, "application/json")
			 .then(result =>{
				if(result.status == 200){
					alert("删除成功")
					location.reload()
				}
			 }).catch(result =>{
				alert(result.bodyText)
				location.reload()
			 })
			 
		  },
		  change2_5(){
			var postbody = {}
			if(this.checked_2_5){
				postbody = {"autoIn2.5": 1}
			}else{
				postbody = {"autoIn2.5": 0}
			}
			console.log(postbody)
			this.$http.post(this.apiUrl+"infochange/", postbody, "application/json")
			 .then(result =>{
				if(result.status == 200){
					alert("切换成功")
					location.reload()
				}
			 }).catch(result =>{
				alert(result.bodyText)
				location.reload()
			 })
			//  location.reload()
		  },
		  change25(){
			var postbody = {}
			if(this.checked_25){
				postbody = {"autoIn25": 1}
			}else{
				postbody = {"autoIn25": 0}
			}
			this.$http.post(this.apiUrl+"infochange/", postbody, "application/json")
			 .then(result =>{
				if(result.status == 200){
					alert("切换成功")
					location.reload()
				}
			 }).catch(result =>{
				alert(result.bodyText)
				location.reload()
			 })
			
		  },
		  change5(){
			var postbody = {}
			console.log(this.checked_5)
			if(this.checked_5){
				postbody = {"autoIn5": 1}
			}else{
				postbody = {"autoIn5": 0}
			}
			this.$http.post(this.apiUrl+"infochange/", postbody, "application/json")
			 .then(result =>{
				if(result.status == 200){
					alert("切换成功")
					location.reload()
				}
			 }).catch(result =>{
				alert(result.bodyText)
				location.reload()
			 })
			 
		  },
		  submitOut(){
			var postbody = {}
			postbody["taskType"] = 1
			console.log(this.outCol)
			console.log(this.outStartRow)
			console.log(this.outEndRow)
			if ((Number(this.outCol)<0 || Number(this.outCol) > 7) && Number(this.radioOut) === 1){
				alert("北区库位列不得小于0或大于7")
				location.reload()
				return 0
			} 
			if ((Number(this.outStartRow)<0 || Number(this.outCol) > 15)&&Number(this.radioOut) ===1){
				alert("北区开始行不得小于0或大于15")
				location.reload()
				return 0
			}
			if ((Number(this.outEndRow)<0 || Number(this.outEndRow) > 15)&&Number(this.radioOut) ===1){
				alert("北区结束行不得小于0或大于15")
				location.reload()
				return 0
			}
			if ((Number(this.outCol)<0 || Number(this.outCol) > 9) && Number(this.radioOut) === 2){
				alert("南区库位列不得小于0或大于9")
				location.reload()
				return 0
			} 
			if ((Number(this.outStartRow)<0 || Number(this.outCol) > 6)&&Number(this.radioOut) ===2){
				alert("南区开始行不得小于0或大于6")
				location.reload()
				return 0
			}
			if ((Number(this.outEndRow)<0 || Number(this.outEndRow) > 6)&&Number(this.radioOut) ===2){
				alert("南区结束行不得小于0或大于6")
				location.reload()
				return 0
			}
			let array = []
			let storageArr = []
			for (let i = Number(this.outStartRow); i <= Number(this.outEndRow); i++) {
				array.push(i);
			}
			for(var singleRow in array){
				var singleStorage = {}
				singleStorage["floor"] = 1
				singleStorage["col"] = Number(this.outCol)
				singleStorage["row"] = Number(singleRow)
				singleStorage["district"] = Number(this.radioOut)
				singleStorage["districtFlag"] = "A"
				storageArr.push(singleStorage)
			}
			postbody["startPoints"] = storageArr
			var endBody = {}
			endBody["floor"] = 1
			endBody["col"] = 0
			endBody["row"] = 0
			endBody["district"] = 1
			endBody["districtFlag"] = "O"
			postbody["endPoints"] = [endBody]
		 	console.log(postbody)
			var testUrl = this.apiUrl+"batchtask/"
			this.$http.post(testUrl, postbody, "application/json")
			.then(result =>{
			if(result.status == 200){
				alert("任务下达成功")
				location.reload()
			}
			}).catch(result =>{
			alert(result.bodyText)
			location.reload()
			}) 
		}
	  },
	  created: function(){
		  this.getAuto()
		  this.getStorage()
	  }
	}
  </script>
  
  <style>
	* {
	  margin: 0;
	  padding: 0;
	}
  
	.content {
	  margin: auto;
	  max-width: 585px;
	  margin-bottom: 100px;
	}
  
	.test-button {
	  display: flex;
	  flex-wrap: wrap;
	}
  
	.btn {
	  display: inline-block;
	  line-height: 1;
	  white-space: nowrap;
	  cursor: pointer;
	  background: #fff;
	  border: 1px solid #c0ccda;
	  color: #1f2d3d;
	  text-align: center;
	  box-sizing: border-box;
	  outline: none;
	  margin: 20px 10px 0px 0px;
	  padding: 9px 15px;
	  font-size: 14px;
	  border-radius: 4px;
	  color: #fff;
	  background-color: #50bfff;
	  border-color: #50bfff;
	  transition: all .2s ease;
	  text-decoration: none;
	  user-select: none;
	}
  
	.des {
	  line-height: 30px;
	}
  
	code.language-html {
	  padding: 10px 20px;
	  margin: 10px 0px;
	  display: block;
	  background-color: #333;
	  color: #fff;
	  overflow-x: auto;
	  font-family: Consolas, Monaco, Droid, Sans, Mono, Source, Code, Pro, Menlo, Lucida, Sans, Type, Writer, Ubuntu, Mono;
	  border-radius: 5px;
	  white-space: pre;
	}
  
	.show-info {
	  margin-bottom: 50px;
	}
  
	.show-info h2 {
	  line-height: 50px;
	}
  
	/*.title, .title:hover, .title-focus, .title:visited {
		  color: black;
	  }*/
  
	.title {
	  display: block;
	  text-decoration: none;
	  text-align: center;
	  line-height: 1.5;
	  margin: 20px 0px;
	  background-image: -webkit-linear-gradient(left, #3498db, #f47920 10%, #d71345 20%, #f7acbc 30%, #ffd400 40%, #3498db 50%, #f47920 60%, #d71345 70%, #f7acbc 80%, #ffd400 90%, #3498db);
	  color: transparent;
	  -webkit-background-clip: text;
	  background-size: 200% 100%;
	  animation: slide 5s infinite linear;
	  font-size: 40px;
	}
  
	.test {
	  height: 285px;
	}
  
	.model {
	  position: fixed;
	  z-index: 10;
	  width: 100vw;
	  height: 100vh;
	  overflow: auto;
	  top: 0;
	  left: 0;
	  background: rgba(0, 0, 0, 0.8);
	}
  
	.model-show {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  width: 100vw;
	  height: 100vh;
	}
  
	.model img {
	  display: block;
	  margin: auto;
	  max-width: 80%;
	  user-select: none;
	  background-position: 0px 0px, 10px 10px;
	  background-size: 20px 20px;
	  background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
	}
  
	.c-item {
	  display: block;
	  padding: 10px 0;
	  user-select: none;
	}
  
	@keyframes slide {
	  0% {
		background-position: 0 0;
	  }
  
	  100% {
		background-position: -100% 0;
	  }
	}
  
	@media screen and (max-width: 1000px) {
	  .content {
		max-width: 90%;
		margin: auto;
	  }
  
	  .test {
		height: 400px;
	  }
	}
  </style>