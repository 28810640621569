<template>
	<div class="RootClass">
		<table align="center" width="100%" class="table xunw_table_form" border="1">
                <tbody>
                <tr>
                    <th  class="zxstyle">ID</th>
                    <th  class="zxstyle">起点</th>
                    <th  class="zxstyle">终点</th>
                    <th  class="zxstyle">创建时间</th>
                    <th  class="zxstyle">下发状态</th>
                    <th  class="zxstyle">执行状态</th>
                    <th  class="zxstyle">接收车辆</th>
                    
                </tr>
                <tr v-for="item in taskList" :key="item.id">
                    <!-- <td align="center" :rowspan="item.kclxspan" :class="{hidden: item.kclxdis}">{{item.kclx}}</td> -->
                    <td>{{item.id}}</td>
                    <td>{{item.task_start_point}}</td>
                    <td>{{item.task_end_point}}</td>
                    <td>{{item.create_time}}</td>
                    <td>{{item.task_release_status}}</td>
                    <td>{{item.task_release_step}}</td>
                    <td>{{item.task_accept_agv}}</td>
                    
                </tr>
                </tbody>
            </table>
		<div style="height: 2rem;"></div>
		<div class= "BottomGuide">
			<router-view />
			<van-tabbar route active-color="#00cc00">
			  <van-tabbar-item replace to="/" icon="home-o">{{$t("bottom.home")}}</van-tabbar-item>
			  <van-tabbar-item replace to="/main" icon="star-o">{{$t("bottom.main")}}</van-tabbar-item>
			  <van-tabbar-item replace to="/advan" icon="fire-o" name="advan-q">{{$t("bottom.advan")}}</van-tabbar-item>
			  <van-tabbar-item replace to="/pack" icon="bag-o" name="package-q">{{$t("bottom.pack")}}</van-tabbar-item>
			</van-tabbar>
		</div>
		<div style="height: 5rem;"></div>
	</div>
<!-- 	</div> -->
</template>

<script>
	export default {
	  data() {
	    return {
			showBox: false,
			showBag: false,
			showDrum: false,
			showContainer: false,
			apiUrl: "http://192.168.200.23:7844/agvserver/",
			taskList: []
	    };
	  },
	  created: function(){
		  this.getTaskList()
	  },
	  methods: {
		  showPopup1() {
		      this.showBox = true;
		  },
		  showPopup2() {
			  this.showBag = true;
		  },
		  showPopup3() {
			  this.showDrum = true;
		  },
		  showPopup4() {
			  this.showContainer = true;
		  },
		  changeCN(){
				  this.$i18n.locale = "zh"
		  },
		  changeEN(){
			  this.$i18n.locale = "en"
		  },
		  getTaskList(){
			this.$http.get(this.apiUrl+"tasklist/")
			 .then(result =>{
				 console.log(result)
				 result = result.body
				 var taskListtt = []
				 for(var singletask in result){
					if (result[singletask]["task_start_point"].indexOf("I01")> -1){
						result[singletask]["task_start_point"] = "北板链"
					}
					if (result[singletask]["task_start_point"].indexOf("I02") > -1){
						result[singletask]["task_start_point"] = "南板链"
					}
					var colC = 0
					var rowR = 0
					var row = 0
					var col = 0
					var direction = 0
					if (result[singletask]["task_end_point"].indexOf("A01") > -1){
						// result[singletask]["task_end_point"] = "北"
						direction = "北区"
						col = result[singletask]["task_end_point"].indexOf("C")
						if(col > -1){
							colC = result[singletask]["task_end_point"].substring(col+1,col+3)
							direction = direction+String(colC)+"列"
						}
						row = result[singletask]["task_end_point"].indexOf("R")
						if(row > -1 && col > -1){
							rowR = result[singletask]["task_end_point"].substring(row+1,row+3)
							direction = direction+ String(rowR) + "行"
							result[singletask]["task_end_point"] = direction
						}
					}
					if (result[singletask]["task_end_point"].indexOf("A02") > -1){
						direction = "南区"
						col = result[singletask]["task_end_point"].indexOf("C")
						if(col > -1){
							colC = result[singletask]["task_end_point"].substring(col+1,col+3)
							direction = direction+String(colC)+"列"
						}
						row = result[singletask]["task_end_point"].indexOf("R")
						if(row > -1 && col > -1){
							rowR = result[singletask]["task_end_point"].substring(row+1,row+3)
							direction = direction + String(rowR) + "行"
							result[singletask]["task_end_point"] = direction
						}
					}
					if (result[singletask]["task_release_status"] === 1){
						result[singletask]["task_release_status"] = "下发成功"
					}
					if (result[singletask]["task_release_status"] === 0){
						result[singletask]["task_release_status"] = "尚未下发"
					}
					if (result[singletask]["task_release_step"] === 0){
						result[singletask]["task_release_step"] = "尚未执行"
					}
					if (result[singletask]["task_release_step"] === 1){
						result[singletask]["task_release_step"] = "执行中"
					}
					if (result[singletask]["task_release_step"] === 2){
						result[singletask]["task_release_step"] = "完成"
					}
					taskListtt.push(result[singletask])
				 }
				 console.log(taskListtt)
				 this.taskList = taskListtt
			 }).catch(error=>{console.log(error)}) 
		  }
	    },
	};
</script>

<style>
	 .background{
	      width:100%;  
	      height:100%;  /**宽高100%是为了图片铺满屏幕 */
	      z-index:-1;
	      position: absolute;
	  }
	   
	  .RootClass{
	      z-index:1;
	      position: relative;
	  }
</style>
