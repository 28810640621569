<!-- 裁剪图片 -->
<template>
	<div class="wrapper">
		<div class="background" style="background-color: slategrey;">
			<!-- <img :src="imgSrc" width="100%" height="100%" alt="" /> -->
		</div>
		<!-- <div style=""></div> -->
		<div style="height: 2rem;"></div>
		<h2>手动任务选择</h2>
		<div style="height: 2rem;"></div>
		<van-tabs type="card" color="#000099" @click="changeTab">
			<van-tab title="入库任务">
				<van-radio-group v-model="radio" style="padding: 2rem;">
					<van-row>
						<van-col>
							<van-radio name="1">北板链</van-radio>
						</van-col>
						<van-col>
							<van-radio name="2">南板链</van-radio>
						</van-col>
					</van-row>
				</van-radio-group>
			</van-tab>
			<van-tab title="调货任务">
				<div class="start-select">
					<h3 style="margin-top: 2rem;">选择开始列</h3>
					<van-stepper v-model="startCol" min="2" max="7"/>
					<h3 style="margin-top: 2rem;">选择开始行</h3>
					<van-stepper v-model="startRow" min="0" max="15"/>
				</div>
			</van-tab>
		</van-tabs>
		<div>----------------------------------</div>
		<div class="start-row-select">
			<h3 style="margin-top: 2rem;">选择结束列</h3>
			<van-stepper v-model="endCol" min="2" max="7"/>
			<h3 style="margin-top: 2rem;">选择结束行</h3>
			<van-stepper v-model="endRow" min="0" max="15"/>
			<div style="height: 2rem;"></div>
		</div>
		<van-button type="info" @click="giveTask">下达任务</van-button>
		<div style="height: 1rem;"></div>
		<div>-----------------------出库任务-----------------------</div>
		<div>
			<van-radio-group v-model="radioOut" style="padding: 1rem;">
						<van-row>
							<van-col>
								<van-radio name="1">北区</van-radio>
							</van-col>
							<van-col>
								<van-radio name="2">南区</van-radio>
							</van-col>
						</van-row>
			</van-radio-group>
			<van-cell-group>
				<van-field v-model="outCol" type="number" label="出库指定列" placeholder="请输入用户名" />
				<van-field v-model="outStartRow" type="number" label="出库开始行" placeholder="请输入用户名" />
				<van-field v-model="outEndRow" type="number" label="出库结束行" placeholder="请输入用户名" />
			</van-cell-group>
			<van-button type="success" @click="submitOut">开始出库</van-button>
		</div>
		
		<div class= "BottomGuide">
			<router-view />
			<van-tabbar route active-color="#00cc00">
			<van-tabbar-item replace to="/" icon="home-o">{{$t("bottom.home")}}</van-tabbar-item>
			<van-tabbar-item replace to="/main" icon="star-o">{{$t("bottom.main")}}</van-tabbar-item>
			<van-tabbar-item replace to="/advan" icon="fire-o" name="advan-q">{{$t("bottom.advan")}}</van-tabbar-item>
			<van-tabbar-item replace to="/pack" icon="bag-o" name="package-q">{{$t("bottom.pack")}}</van-tabbar-item>
			</van-tabbar>
		</div>
	</div>
  </template>
  
  <script>
  
	import {Dialog} from "vant"
	import {ref} from "vue"
	// import * as OSS from 'ali-oss';
	export default {
	  components: {
		[Dialog.Component.name]: Dialog.Component,
	  },
	  data() {
		return {
		  chooseFlag: 0,
		  radio: '1',
		  radioOut: '1',
		  startCol: 2,
		  startRow: 0,
		  endCol: 2,
		  endRow: 0,
	      active: "home-q",
		  model: false,
		  modelSrc: '',
		  crap: false,
		  previews: {},
		  show: false,
		  imgAgv: require("/src/assets/agvIco.png"),
		  // apiFlag :"Checking",
		  form: {
			head: ''
		  },
		  oriImg: '',
		  oriImgType: '',
		  apiUrl: "http://192.168.200.23:7844/agvserver/",
		  agvs :[],
		  chargePos_one: 0,
		  chargePos_two: 0,
		  agvSingle: '',
		  currentRate: 0,
		  outCol: 0,
		  outStartRow: 0,
		  outEndRow: 15,
		}
	  },
	  setup(){
		  const fileList = ref([
				{
				  base64: this.modelSrc,
				  deletable: false,
				  imageFit: 'contain',
				  previewSize: 500,
				},
			  ]);
		  return { fileList };
	  },
	  computed: {
		text() {
			return this.currentRate.toFixed(0) + '%'
		  }
		},
	  methods: {
		giveTask(){
			
			
			var taskType;
			var startDistrictFlag;
			var startCol;
			var startRow;
			var startFloor;
			var startDistrict;
			if (this.chooseFlag < 1){
				console.log("入库任务")
				taskType = 0
				startDistrictFlag = "I"
				startCol = 0
				startRow = 0
				startFloor = 1
				startDistrict = Number(this.radio)
			}else{
				if(this.chooseFlag === 1 && this.startCol === this.endCol && this.startRow === this.endRow){
					alert("开始和结束库位不能一样")
					location.reload()
					return void 0
				}
				// let taskType = 2
				console.log("调货任务")
				taskType = 2
				startDistrictFlag = "A"
				startCol = this.startCol
				startRow = this.startRow
				startFloor = 1
				startDistrict = 1 
			}
			var endDistrictFlag = "A"
			var endCol = this.endCol
			var endRow = this.endRow
			var endFloor = 1
			var endDistrict = 1

			let startDict = {"floor": startFloor, "col":startCol, "row":startRow, 
			"district":startDistrict, "districtFlag": startDistrictFlag}

			let endDict = {"floor": endFloor, "col":endCol, "row":endRow, 
			"district":endDistrict, "districtFlag": endDistrictFlag}

			let postBody = {"taskType": taskType, "startPoints": [startDict], "endPoints": [endDict]}
			let goodsBody=	{
					"StorageDetailCompany": "0302Test",
					"StorageDetailStandard": "0302Test",
					"StorageDetailBatch": "0302Test",
					"StorageDetailInner": "0302Test",
					"StorageDetailOuter": "0302Test"
				}
			if (taskType === 0){
				postBody["goodsInfo"] = goodsBody
			}
			console.log(postBody)
			this.$http.post(this.apiUrl+"batchtask/", postBody, "application/json")
			 .then(result =>{
				//  console.log(result)
				if(result.status == 200){
					alert("设定成功")
					location.reload()
				}
				location.reload()
			 }).catch(error=>{
				alert(error.bodyText)
				location.reload()
			}) 
		},
		changeTab(index, title){
			this.chooseFlag = index
			console.log(index)
			console.log(title)

		},
		submitOut(){
			var postbody = {}
			postbody["taskType"] = 1
			console.log(this.outCol)
			console.log(this.outStartRow)
			console.log(this.outEndRow)
			if ((Number(this.outCol)<0 || Number(this.outCol) > 7) && Number(this.radioOut) === 1){
				alert("北区库位列不得小于0或大于7")
				location.reload()
				return 0
			} 
			if ((Number(this.outStartRow)<0 || Number(this.outCol) > 15)&&Number(this.radioOut) ===1){
				alert("北区开始行不得小于0或大于15")
				location.reload()
				return 0
			}
			if ((Number(this.outEndRow)<0 || Number(this.outEndRow) > 15)&&Number(this.radioOut) ===1){
				alert("北区结束行不得小于0或大于15")
				location.reload()
				return 0
			}
			if ((Number(this.outCol)<0 || Number(this.outCol) > 9) && Number(this.radioOut) === 2){
				alert("南区库位列不得小于0或大于9")
				location.reload()
				return 0
			} 
			if ((Number(this.outStartRow)<0 || Number(this.outCol) > 6)&&Number(this.radioOut) ===2){
				alert("南区开始行不得小于0或大于6")
				location.reload()
				return 0
			}
			if ((Number(this.outEndRow)<0 || Number(this.outEndRow) > 6)&&Number(this.radioOut) ===2){
				alert("南区结束行不得小于0或大于6")
				location.reload()
				return 0
			}
			let array = []
			let storageArr = []
			console.log(this.outStartRow)
			for (let i = Number(this.outStartRow); i <= Number(this.outEndRow); i++) {
				array.push(i);
			}
			console.log(array)
			for(let singleRow of array){
				console.log(singleRow)
				let singleStorage = {}
				singleStorage["floor"] = 1
				singleStorage["col"] = Number(this.outCol)
				singleStorage["row"] = Number(singleRow)
				singleStorage["district"] = Number(this.radioOut)
				singleStorage["districtFlag"] = "A"
				storageArr.push(singleStorage)
			}
			postbody["startPoints"] = storageArr
			var endBody = {}
			endBody["floor"] = 1
			endBody["col"] = 0
			endBody["row"] = 0
			endBody["district"] = 1
			endBody["districtFlag"] = "O"
			postbody["endPoints"] = [endBody]
		 	console.log(postbody)
			var testUrl = this.apiUrl+"batchtask/"
			this.$http.post(testUrl, postbody, "application/json")
			.then(result =>{
			if(result.status == 200){
				alert("任务下达成功")
				location.reload()
			}
			}).catch(result =>{
			alert(result.bodyText)
			location.reload()
			}) 
		}
		  // onRead(file) {
		  //     this.ifimg = true;
		  //     this.modelSrc = file.content
		  //     this.oriImg = file
		  //     this.oriImgType = file.file.type
		  //     let canvas = document.createElement('canvas'); // 创建Canvas对象(画布)
		  //     let context = canvas.getContext('2d');
		  //     let img = new Image();
		  //     img.src = file.content;                         // 指定图片的DataURL(图片的base64编码数据)
		  //     img.onload = function () {
		  //         // 画布大小 这里的this指向img
		  //         canvas.width = this.width;
		  //         canvas.height = this.height;
		  //         context.drawImage(img, 0, 0, this.width, this.height);  // 图片大小
		  //         file.content = canvas.toDataURL(file.file.type, 0.3);   // 0.92为默认压缩质量
		  //         this.oriImg = file.content
		  //     };
		  // },
		  
	  },
	  
	}
  </script>
  
  <style>
	* {
	  margin: 0;
	  padding: 0;
	}
  
	.content {
	  margin: auto;
	  max-width: 585px;
	  margin-bottom: 100px;
	}
  
	.test-button {
	  display: flex;
	  flex-wrap: wrap;
	}
  
	.btn {
	  display: inline-block;
	  line-height: 1;
	  white-space: nowrap;
	  cursor: pointer;
	  background: #fff;
	  border: 1px solid #c0ccda;
	  color: #1f2d3d;
	  text-align: center;
	  box-sizing: border-box;
	  outline: none;
	  margin: 20px 10px 0px 0px;
	  padding: 9px 15px;
	  font-size: 14px;
	  border-radius: 4px;
	  color: #fff;
	  background-color: #50bfff;
	  border-color: #50bfff;
	  transition: all .2s ease;
	  text-decoration: none;
	  user-select: none;
	}
  
	.des {
	  line-height: 30px;
	}
  
	code.language-html {
	  padding: 10px 20px;
	  margin: 10px 0px;
	  display: block;
	  background-color: #333;
	  color: #fff;
	  overflow-x: auto;
	  font-family: Consolas, Monaco, Droid, Sans, Mono, Source, Code, Pro, Menlo, Lucida, Sans, Type, Writer, Ubuntu, Mono;
	  border-radius: 5px;
	  white-space: pre;
	}
  
	.show-info {
	  margin-bottom: 50px;
	}
  
	.show-info h2 {
	  line-height: 50px;
	}
  
	/*.title, .title:hover, .title-focus, .title:visited {
		  color: black;
	  }*/
  
	.title {
	  display: block;
	  text-decoration: none;
	  text-align: center;
	  line-height: 1.5;
	  margin: 20px 0px;
	  background-image: -webkit-linear-gradient(left, #3498db, #f47920 10%, #d71345 20%, #f7acbc 30%, #ffd400 40%, #3498db 50%, #f47920 60%, #d71345 70%, #f7acbc 80%, #ffd400 90%, #3498db);
	  color: transparent;
	  -webkit-background-clip: text;
	  background-size: 200% 100%;
	  animation: slide 5s infinite linear;
	  font-size: 40px;
	}
  
	.test {
	  height: 285px;
	}
  
	.model {
	  position: fixed;
	  z-index: 10;
	  width: 100vw;
	  height: 100vh;
	  overflow: auto;
	  top: 0;
	  left: 0;
	  background: rgba(0, 0, 0, 0.8);
	}
  
	.model-show {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  width: 100vw;
	  height: 100vh;
	}
  
	.model img {
	  display: block;
	  margin: auto;
	  max-width: 80%;
	  user-select: none;
	  background-position: 0px 0px, 10px 10px;
	  background-size: 20px 20px;
	  background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
	}
  
	.c-item {
	  display: block;
	  padding: 10px 0;
	  user-select: none;
	}
  
	@keyframes slide {
	  0% {
		background-position: 0 0;
	  }
  
	  100% {
		background-position: -100% 0;
	  }
	}
  
	@media screen and (max-width: 1000px) {
	  .content {
		max-width: 90%;
		margin: auto;
	  }
  
	  .test {
		height: 400px;
	  }
	}
  </style>